import React, {useState, useEffect, useMemo, useContext} from 'react';
import Api from "../helpers/ApiHelper";
import UserContext from "../context/user/userContext";
import {
  createTable,
  useTableInstance,
  getCoreRowModel,
} from "@tanstack/react-table";

const table = createTable();

const CommonNumber = () => {
  const context = useContext(UserContext);
  const { setIsLoading } = context;
  const [results, setResults] = useState([]);


  const getResult = async () => {    
    const postData = {
      str1: "",
      str2: ""
    };
    var headers = {
      "Content-Type": "application/json"
    };
    setIsLoading(true);
    const res = await Api.post(`/api/getNewCommonNumbers`, postData, {
      headers: headers,
    }).catch((error) => {      
      return 0;
    });
    setIsLoading(false);    
    if (res.status === 200) {
      if (res.data.str1.toString() === "1") {
        setResults(res.data.str2);
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    getResult(); 
    // eslint-disable-next-line
  }, [])
  
  const resultData = useMemo(() => [...results], [results]);

  const columns = useMemo(
    () =>
    results[0]
        ? Object.keys(results[0])
            .filter(
              (key) => key !== "id" && key !== "date" && key !== "round"
              )
            .map((key) => {              
              return table.createDataColumn(key, {
                id: key,
                header: key,
                accessor: key,
              });
            })
        : [],
    [results]
  );

  const instance = useTableInstance(table, {
    data: resultData,
    columns,    
    getCoreRowModel: getCoreRowModel()    
  }); 

  return (
    <main id="main">
    <section className="breadcrumbs">
      <div className="container">        
        <h2 style={{textAlign: "center"}}>Teer Shillong Morning Common Numbers</h2>
      </div>
    </section>

    <section className="inner-page">
      <div className="container">
      <div className='row'>
        <div className='col-12'>
          <p style={{textAlign: "center"}}><strong>Get best predicted common number for teer Shillong Morning</strong></p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table className="table table-bordered border-dark table-sm table-outline-dark common-table">
            <thead>
              <tr>
                <th colSpan={3} style={{ textAlign: "center" }}>{results[0]?results[0].date: ""}</th>
              </tr>
              <tr>
                <th colSpan={3} style={{ textAlign: "center", fontWeight: "normal" }}>Teer Shillong Morning</th>
              </tr>
              {instance.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} style={{backgroundColor: "#3fd5ba"}}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} style={{ textAlign: "center" }}>
                      {header.isPlaceholder ? null : header.renderHeader()}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {instance.getRowModel().rows
              .filter((row) => resultData[row.index].round === 1)
              .map((row) => (                
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {cell.renderCell()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> 
      <div className='row'>
        <div className='col-12'>
        <table className="table table-bordered border-dark table-sm table-outline-dark common-table">
            <thead>              
              <tr>
                <th colSpan={3} style={{ textAlign: "center", fontWeight: "normal", backgroundColor: "#3fd5ba" }}>Teer Shillong Morning</th>
              </tr>
              {instance.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} style={{ textAlign: "center" }}>
                      {header.isPlaceholder ? null : header.renderHeader()}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {instance.getRowModel().rows
              .filter((row) => resultData[row.index].round === 2)
              .map((row) => (                
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {cell.renderCell()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <p style={{textAlign: "center"}}><strong>Disclaimer</strong>: Teer Shillong Morning common number are purely based on certain calculations done using past results. There is no guarantee of the accuracy of these numbers.</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <p style={{textAlign: "center"}}><strong>Note</strong>: There is no making number in Teer</p>
        </div>
      </div>
      </div>
    </section>
  </main>
  )
}

export default CommonNumber