import React, {useState, useEffect, useMemo, useContext} from 'react';
import Api from "../helpers/ApiHelper";
import UserContext from "../context/user/userContext";
import {
  createTable,
  useTableInstance,
  getCoreRowModel,
} from "@tanstack/react-table";

const table = createTable();

const Result = () => {
  const context = useContext(UserContext);
  const pusherClient = context.pusherClient;
  const { setIsLoading } = context;
  const [info, setInfo] = useState([]);
  const [result, setResult] = useState([]);

  const getInfo = async () => {    
    const postData = {
      str1: "",
      str2: ""
    };
    var headers = {
      "Content-Type": "application/json"
    };
    const res = await Api.post(`/api/getCompanySettings`, postData, {
      headers: headers,
    }).catch((error) => {      
      return 0;
    });    
    if (res.status === 200) {
      if (res.data.str1.toString() === "1") {
        setInfo(res.data.str2);
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const getResult = async () => {    
    const postData = {
      str1: "",
      str2: ""
    };
    var headers = {
      "Content-Type": "application/json"
    };
    setIsLoading(true);
    const res = await Api.post(`/api/getLatestResult`, postData, {
      headers: headers,
    }).catch((error) => {      
      return 0;
    });    
    setIsLoading(false);
    if (res.status === 200) {
      if (res.data.str1.toString() === "1") {
        setResult(res.data.str2);
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    getInfo();
    getResult(); 
    const channel = pusherClient.subscribe("teer-channel");
    channel.bind("result-event", (data) => {      
      getResult(); 
    });
    return () => {
      pusherClient.unsubscribe("teer-channel");
      pusherClient.disconnect();
    };
    // eslint-disable-next-line
  }, [])

  const resultData = useMemo(() => [...result], [result]);

  const columns = useMemo(
    () =>
    result[0]
        ? Object.keys(result[0])
            .filter(
              (key) => key !== "id" && key !== "game_date" 
              )
            .map((key) => {
              return table.createDataColumn(key, {
                id: key,
                header: key.toUpperCase(),
                accessor: key,
              });
            })
        : [],
    [result]
  );

  const instance = useTableInstance(table, {
    data: resultData,
    columns,    
    getCoreRowModel: getCoreRowModel()    
  });

  return (
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">          
          {/* <h2 style={{textAlign: "center"}}>Results</h2> */}
        </div>
      </section>

      <section className="inner-page">
        <div className="container">
          <div className='row'>
            <div className='col-md-12'>
              <h2 style={{ textAlign: "center", fontWeight : "600", color: "#983199", fontFamily: "roboto", marginBottom: "60px"}}>{info[0]? info[0].company_name : ""}</h2>
            </div>
          </div>
        <div className="row">
        <div className="col-md-12" style={{display: "flex", justifyContent: "center"}}>
          <table className="table table-bordered border-dark table-sm table-outline-dark table-mobile" style={{width: "85%"}}>
            <thead>
              <tr>
                <th colSpan={2} style={{ textAlign: "center" }}>{(result[0])? result[0].game_date : ""}</th>
              </tr>
              <tr>
                <th colSpan={2} style={{ textAlign: "center", fontWeight: "normal" }}>SHILLONG MORNING</th>
              </tr>
              <tr style={{ backgroundColor: "#3fd5ba"}}>
                <th style={{ textAlign: "center" }}>F/R ({(info[0])? info[0].fr_time : ""})</th>
                <th style={{ textAlign: "center" }}>S/R ({(info[0])? info[0].sr_time : ""})</th>
              </tr>
            </thead>
            <tbody>
              {instance.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {cell.renderCell()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> 
        </div>
      </section>
    </main>
  )
}

export default Result